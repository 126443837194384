import React from 'react'
import Img from 'gatsby-image'

import { BsArrowRight } from 'react-icons/bs'

export default function BehindScenes ( props ) {

	const { behindTheScenes } = props

	return (
		<div className="behind-scenes__list">
			{ behindTheScenes.map( item => (
				<div className="behind-scenes__item" key={ item.id }>
					<div className="behind-scenes__text-container">
						<h3 className="behind-scenes__title">
							{ item.name } - <span className="behind-scenes__role">{ item.role }</span>
						</h3>
						<p className="behind-scenes__summary">{ item.summary }</p>
						<a className="behind-scenes__link" href={ item.url } target="_blank" rel="noreferrer">
							Visit website
							<BsArrowRight className="behind-scenes__link-icon" />
						</a>
					</div>
					<div className="behind-scenes__image-container">
						<Img fluid={{ ...item.image.imageFile.childImageSharp.fluid, aspectRatio: 1/1 }} className="behind-scenes__image" />
					</div>
				</div>
			) ) }
		</div>
	)
}